export const MENU_ITEMS = {
  data: {
    menus: {
      nodes: [
        {
          id: "dGVybToy",
          databaseId: 2,
          name: "Menu 1",
          menuItems: {
            edges: [
              {
                node: {
                  id: "cG9zdDoyMTYwOTE5",
                  label: "BBL",
                  parentId: null,
                  target: null,
                  url: "https://scplaybook.com.au/bbl",
                  uri: "/category/bbl",
                  title: null,
                },
              },
              {
                node: {
                  id: "cG9zdDoxOTUzOTc3",
                  label: "NRL",
                  parentId: null,
                  target: null,
                  url: "https://scplaybook.com.au/nrl",
                  uri: "/category/nrl",
                  title: null,
                },
              },

              {
                node: {
                  id: "cG9zdDoxMzg=",
                  label: "Contributors",
                  parentId: null,
                  target: null,
                  url: "/contributors",
                  uri: "/contributors",
                  title: null,
                },
              },
              // {
              //   node: {
              //     id: "cG9zdDoyMjMy",
              //     label: "VC Loophole",
              //     parentId: null,
              //     target: null,
              //     url: "https://scplaybook.com.au/vc-loophole-calculator/",
              //     uri: "/vc-loophole-calculator/",
              //     title: null,
              //   },
              // },
              {
                node: {
                  id: "cG9zdDoyMjMy1",
                  label: "Videos",
                  parentId: null,
                  target: null,
                  url: "/videos",
                  uri: "/videos",
                  title: null,
                },
              },
              {
                node: {
                  id: "cG9zdDoxOTYzMDMy",
                  label: "Stat HQ",
                  parentId: null,
                  target: null,
                  url: "https://bbl.scplaybook.com.au/",
                  uri: "https://bbl.scplaybook.com.au/",
                  title: null,
                },
              },
            ],
          },
        },
      ],
    },
  },
  extensions: {
    debug: [],
    queryAnalyzer: {
      keys: "25a890f403972d895ad421b13b364c8260bb0fb82b3ef68fe908df8a9303f128 graphql:Query list:menu list:menuitem dGVybToy cG9zdDoyMTYwOTE5 cG9zdDoxOTUzOTc3 cG9zdDoxOTUzOTc4 cG9zdDoxNDU= cG9zdDoxMzg= cG9zdDoyMjMy cG9zdDoxOTYzMDMy",
      keysLength: 218,
      keysCount: 12,
      skippedKeys: "",
      skippedKeysSize: 0,
      skippedKeysCount: 0,
      skippedTypes: [],
    },
  },
};
