import { GTAG_ID } from "@/src/constants";
import Head from "next/head";

export interface GenericHeadProps {
  title?: string;
  description?: string;
  ogTitle?: string;
  image?: string;
}
const GenericHead = ({
  title = "SCPB: NRL, AFL, BBL Supercoach News",
  description = "SuperCoach Playbook - NRL, AFL, BBL Supercoach News",
  ogTitle = "SuperCoach Playbook - NRL, AFL, BBL Supercoach News",
  image = "",
}: GenericHeadProps) => {
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" key="desc" content={description} />
      <meta name="og:description" content={description} />
      {image && <meta name="og:image" content={image} />}
      <meta name="og:title" content={title} />

      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${GTAG_ID}`}
      />
      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GTAG_ID}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
    </Head>
  );
};

export default GenericHead;
