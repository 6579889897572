import {
  isBefore,
  isAfter,
  isSameDay,
  format,
  parseISO,
  subDays,
} from "date-fns";

const test = "2024-05-02T19:11:01";

export function humanReadableDate(comparisonDate: string) {
  const today = new Date();
  const yesterday = subDays(today, 1);
  const aWeekAgo = subDays(today, 7);
  const twoWeeksAgo = subDays(today, 14);
  const threeWeeksAgo = subDays(today, 21);

  // Get the date in English locale to match English day of week keys
  const compare = new Date(comparisonDate);

  let result = "";
  if (isSameDay(compare, today)) {
    result = "Today";
  } else if (isSameDay(compare, yesterday)) {
    result = "Yesterday";
  } else if (isAfter(compare, aWeekAgo)) {
    result = `${format(compare, "EEEE")}`;
  } else if (isAfter(compare, twoWeeksAgo)) {
    result = compare.toLocaleDateString("en-GB");
  } else if (isBefore(compare, threeWeeksAgo)) {
    result = compare.toLocaleDateString("en-GB");
  } else {
    // we are lookign at a date which was a while ago:
    result = `${format(compare, "dd/MM/yyyy")}`;
  }

  return result;
}

export function msToHoursMinutes(time: number) {
  return time.toString();
}

export const isTheSameDay = (date1: string, date2: string): boolean => {
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
};
